<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60.007"
    height="60"
    viewBox="0 0 60.007 60"
  >
    <g
      id="Raggruppa_8333"
      data-name="Raggruppa 8333"
      transform="translate(-1319.72 698.182)"
    >
      <path
        id="Tracciato_7906"
        data-name="Tracciato 7906"
        d="M1347.849-698.181h3.75c1,.129,2.011.224,3.007.393a29.492,29.492,0,0,1,15.036,7.207,29.509,29.509,0,0,1,9.624,17.233c.194,1.091.309,2.2.461,3.294v3.75c-.088.714-.163,1.43-.265,2.141a29.011,29.011,0,0,1-8.276,16.883c-7.7,7.677-16.986,10.533-27.651,8.426a28.671,28.671,0,0,1-17.619-11.131,29.157,29.157,0,0,1-5.91-22.247,29.053,29.053,0,0,1,8.353-16.969,29.383,29.383,0,0,1,16.2-8.521C1345.647-697.912,1346.751-698.029,1347.849-698.181ZM1357.7-684.5c-2.134,0-4.207-.109-6.264.024a6.259,6.259,0,0,0-6.224,5.8,43.041,43.041,0,0,0-.146,4.677c-.006.225,0,.451,0,.748h-3.394v6.134h3.4v17.348h7.242v-17.373h4.876c.211-2.056.417-4.062.631-6.153h-5.507c0-1.308-.026-2.537.008-3.766a1.423,1.423,0,0,1,1.59-1.493c1.054-.019,2.109,0,3.163-.007.2,0,.41-.021.625-.032Z"
        fill="#363636"
      />
    </g>
  </svg>
</template>

<style scoped></style>
