<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60.015"
    height="60"
    viewBox="0 0 60.015 60"
  >
    <g
      id="Raggruppa_8332"
      data-name="Raggruppa 8332"
      transform="translate(-423.259 602.689)"
    >
      <path
        id="Tracciato_7902"
        data-name="Tracciato 7902"
        d="M451.387-602.69h3.75c1.022.133,2.052.227,3.065.407a29.4,29.4,0,0,1,18.908,11.455,29.083,29.083,0,0,1,5.6,23.757,28.857,28.857,0,0,1-10.159,17.313,29.225,29.225,0,0,1-23.342,6.789,28.869,28.869,0,0,1-15.814-7.272,29.538,29.538,0,0,1-9.672-17.217c-.2-1.11-.314-2.235-.468-3.353v-3.75c.14-1.041.248-2.087.425-3.121a29.408,29.408,0,0,1,8.218-16.033,29.388,29.388,0,0,1,16.192-8.513C449.185-602.421,450.289-602.538,451.387-602.69Zm28.362,29.975a26.528,26.528,0,0,0-26.529-26.459,26.53,26.53,0,0,0-26.444,26.483,26.535,26.535,0,0,0,26.5,26.49A26.531,26.531,0,0,0,479.748-572.715Z"
        fill="#363636"
      />
      <path
        id="Tracciato_7903"
        data-name="Tracciato 7903"
        d="M548.15-356.131a13.972,13.972,0,0,1-11.52-6.733,1.755,1.755,0,0,1,.462-2.528,1.763,1.763,0,0,1,2.5.627,10.309,10.309,0,0,0,7.959,5,10.281,10.281,0,0,0,10.029-4.838,2.071,2.071,0,0,1,1.278-1.042,1.7,1.7,0,0,1,1.818.671,1.765,1.765,0,0,1,.033,2.039,14.082,14.082,0,0,1-6.19,5.5A14.926,14.926,0,0,1,548.15-356.131Z"
        transform="translate(-95.381 -199.922)"
        fill="#363636"
      />
      <path
        id="Tracciato_7904"
        data-name="Tracciato 7904"
        d="M646.272-487.409a7.71,7.71,0,0,1,7.189,4.953,1.8,1.8,0,0,1-.914,2.459,1.783,1.783,0,0,1-2.36-1.134,4.172,4.172,0,0,0-3.871-2.748,4.186,4.186,0,0,0-3.981,2.767,1.786,1.786,0,0,1-2.086,1.206,1.761,1.761,0,0,1-1.356-1.821,2.51,2.51,0,0,1,.168-.679A7.7,7.7,0,0,1,646.272-487.409Z"
        transform="translate(-181.911 -97.241)"
        fill="#363636"
      />
      <path
        id="Tracciato_7905"
        data-name="Tracciato 7905"
        d="M504.529-487.409a7.707,7.707,0,0,1,7.206,5.014,1.785,1.785,0,0,1-.939,2.4,1.764,1.764,0,0,1-2.331-1.083,4.13,4.13,0,0,0-3.959-2.79,4.093,4.093,0,0,0-3.942,2.813,1.748,1.748,0,0,1-2.289,1.075,1.771,1.771,0,0,1-.984-2.377A7.71,7.71,0,0,1,504.529-487.409Z"
        transform="translate(-62.34 -97.241)"
        fill="#363636"
      />
    </g>
  </svg>
</template>

<style scoped></style>
