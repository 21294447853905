<script setup lang="ts">
import IbanCalendar from "@/components/icons/IbanCalendar.vue";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";
import type { IValue } from "~/interfaces";

defineComponent({
  name: "RadioButton",
});

const props = defineProps({
  values: {
    type: Array as () => IValue[],
    default: () => [],
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  class: {
    type: String,
    required: false,
    default: undefined,
  },
  calendar: {
    type: Boolean,
    required: false,
    default: false,
  },
  error: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
    default: "",
  },
  unselect: {
    type: Boolean,
    required: false,
    default: false,
  }
});

const slots = useSlots();

const selectedOption = defineModel<any>();

const emit = defineEmits(["value"]);

const uid = getCurrentInstance()?.uid;


const handleSelection = (value: any) => {
  if(props.unselect){
    selectedOption.value = selectedOption.value === value ? null : value;
    emit('value', null)
  }else{
    emit('value', value)
  }

}
</script>

<template>
  <div class="primary">
    <div :class="[slots.default && 'flex-col-reverse md:flex-row flex md:gap-5']">
      <div class="radio-button-container">
        <label
          v-for="(item, index) in values"
          :key="index"
          :for="`radioComponent${uid}${index}`"
          :class="[
            calendar ? 'calendarRadio' : 'normalRadio',
            props.class,
            selectedOption == item.value && !calendar ? 'primary' : '',
          ]"
        >
          <IbanCalendar
            v-if="calendar"
            class="background-svg z-10"
            :color="selectedOption == item.value ? 'var(--yellow)' : '#fff'"
          />
          <input
            :id="`radioComponent${uid}${index}`"
            v-model="selectedOption"
            type="radio"
            :value="item.value"
            class="hidden"
            @click="() => handleSelection(item.value)"
          />
          <label
            class="block text-lg font-semibold cursor-pointer z-20"
            :class="[calendar && 'mt-[5px]']"
            :for="`radioComponent${uid}${index}`"
            >{{ item.label }}
          </label>
        </label>
      </div>
      <slot />
    </div>
    <ErrorMessage :small="false" v-if="error" :message="errorMessage" />
  </div>
</template>

<style scoped></style>
