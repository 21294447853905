<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";

defineProps({
  title: { type: String, required: true },
  text: { type: String, required: false, default: null },
});

const emit = defineEmits(["init"])
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <div
        class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700 text-center pb-48 pt-36"
      >
        <div class="w-full text-center">
          <div class="spinner mx-auto"></div>
        </div>
        <h1 class="text-lg font-semibold mt-6">{{ title }}</h1>
        <span v-if="text != null" v-html="text"></span>
        <slot name="text" v-if="text == null"/>
      </div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
