<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import YouTubeIframeLoader  from 'youtube-iframe';

const acceptedCookie = getStateValue("ytAcceptedCookie")
const { videoId } = defineProps({
  videoId: {
    type: String,
    required: true,
  },
});

const playerId = computed(() => {
  return `player${uuidv4()}`;
});

const playerState = ref<number | null>(null);

const videoPlayerContainer = ref<HTMLDivElement | null>(null)

/**
 * Initialize YT Player
 * https://developers.google.com/youtube/iframe_api_reference
 */
const initializePlayer = () => {
  if(videoPlayerContainer.value){
    YouTubeIframeLoader.load(function(YT) {
      window.YTplayer = new YT.Player(videoPlayerContainer.value?.id, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          wmode: 'transparent',
          autoplay: 0,
          rel: 0,
          loop: 0,
          controls: 1,
          showinfo: 0,
          autohide: 1,
          modestbranding: 1,
          vq: 'hd1080'
        },
        events: {
          'onStateChange': onPlayerStateChange,
        }
      });
    });
  }
}

/**
 * API Reference
 * https://developers.google.com/youtube/iframe_api_reference?hl=es#onStateChange
 * @param state
 */
const onPlayerStateChange = (state: any) => {
  playerState.value = state.data

  if(state.data === 0) window.YTplayer.stopVideo() //Stop the video when it ends
}

/**
 * Play video in "iframe"
 */
const play = () => {
  try {
    if (acceptedCookie) {
      window.YTplayer?.playVideo();
    } else {
      fallbackRedirect(`https://www.youtube.com/watch?v=${videoId}`);
    }
  } catch {
    fallbackRedirect(`https://www.youtube.com/watch?v=${videoId}`);
  }
}

onMounted(() => {
  initializePlayer()
})
</script>
<template>
  <div class="w-full">
    <div
        :class="`rounded-3xl w-full bg-black flex items-center justify-center overflow-hidden relative z-20 h-[400px] ${[1,2,3].includes(playerState ?? -1) ? 'opacity-0 pointer-events-none' : ''}`">
      <img :src="`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`" :alt="videoId" class="absolute w-full h-full top-0 left-0 opacity-80 z-0" />
      <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-white h-16 w-16 block cursor-pointer z-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          @click="play()"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
        />
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>

    <div
        ref="videoPlayerContainer"
        :id="playerId"
        class="rounded-3xl w-full absolute top-0 left-0 h-[400px] z-0">
    </div>
  </div>
</template>


<style scoped></style>
