<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import IdentityCard from "~/components/inputs/IdentityCard.vue";
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import AutoComplete from "~/components/inputs/AutoComplete.vue";
import Accordion from "~/components/ui/Accordion.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import SelectDate from "~/components/inputs/SelectDate.vue";
import jsonCities from "~/data/cities.json";
import type { ICity, IValue } from "~/interfaces";
import type { Ref } from "vue";
import Validator from "~/utils/validator";
import { useVuelidate } from "@vuelidate/core";
import identityFormRules from "~/rules/identityDocData";
import { DateTime } from "luxon";

const locale = await getLocale("forms", "identity_card")

defineComponent({
  name: "IdentityDocData",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const comunes: Ref<IValue[]> = ref([]);

onMounted(() => {
  jsonCities.forEach((city: ICity) => {
    comunes.value.push({
      value: city.Prov,
      label: `${city.Citta} (${city.Prov})`,
    });
  });
  emit("init")
});

const identityType = useState<number | null>("identityType", () => null);
const documentNumber = useState<string | null>("documentNumber", () => null);
const documentCity = useState<string | null>("documentCity", () => null);
const documentExpire = useState<string | null>("documentExpire", () => null);
const documentRelease = useState<string | null>("documentRelease", () => null);

const docEnum = {
  [DOCS.IDENTITY_CARD]: "identity_card",
  [DOCS.PASSPORT]: "passport",
  [DOCS.DRIVERS_LICENSE]: "drivers_license",
};
const currentNumDocHelpers = computed(() => {
  const key = docEnum[identityType.value];
  return locale.document_help_options[key].num;
});
const currentLocDocHelpers = computed(() => {
  const key = docEnum[identityType.value];
  return locale.document_help_options[key].loc;
});

const userDate = computed(() => {
  const { year, month, day } = userTaxCode();
  return { day, year, month };
});

const maxYearExpire = computed(() => {
  const currentDate = DateTime.now();
  const maxDate = currentDate.plus({ years: 11 });
  return maxDate.year;
});

const minYearExpire = computed(() => {
  const currentYear = DateTime.now();
  return currentYear.year
});

const emit = defineEmits(["init", "submit", "goBack", "change"]);

const v$ = useVuelidate(identityFormRules, {
  identityType,
  documentNumber,
  documentCity,
  documentExpire,
  documentRelease,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const resetForm = () => {
  documentNumber.value = "";
  documentCity.value = "";
  documentExpire.value = "";
  documentRelease.value = "";
  v$.value.$reset();
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};

const change = () => {
  emit("change");
  resetForm();
};
watch(identityType, resetForm)

</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" :has-submit="!!identityType" @submit="submit">
        <template #before>
          <div class="flex flex-wrap justify-between pt-5 px-4 sm:px-8">
            <p class="w-full">{{ locale.text }}</p>
            <div class="w-full flex justify-center items-center">
              <IdentityCard v-model="identityType" @change="change()"></IdentityCard>
            </div>
          </div>
        </template>

        <template v-if="identityType">
          <div class="w-full lg:w-1/2 lg:pr-4 mb-3">
            <InputFloatingLabel
              v-if="identityType == DOCS.IDENTITY_CARD"
              v-model="documentNumber"
              type="text"
              :mask="identityCardMask"
              :label="locale.inputs.document_number"
              :class="`${identityType != DOCS.IDENTITY_CARD ? 'hidden' : ''}`"
              :error="v$.documentNumber.$error"
              :error-message="Validator.message(v$.documentNumber)"
              test-id="documentNumber"
            />

            <InputFloatingLabel
              v-if="identityType == DOCS.PASSPORT"
              v-model="documentNumber"
              type="text"
              :mask="passportMask"
              :label="locale.inputs.document_number"
              :class="`${identityType != DOCS.PASSPORT ? 'hidden' : ''}`"
              :error="v$.documentNumber.$error"
              :error-message="Validator.message(v$.documentNumber)"
              test-id="documentNumber"
            />

            <InputFloatingLabel
              v-if="identityType == DOCS.DRIVERS_LICENSE"
              v-model="documentNumber"
              type="text"
              :mask="drivingLicenseMask"
              :label="locale.inputs.document_number"
              :error="v$.documentNumber.$error"
              :error-message="Validator.message(v$.documentNumber)"
              test-id="documentNumber"
            />

            <Accordion>
              <p class="text-xs pt-2" v-html="locale.inputs.document_help_num"></p>

              <template #items>
                <div class="my-5 border-y pt-5">
                  <div
                    v-for="(fig, i) in currentNumDocHelpers"
                    :key="i"
                    class="flex flex-col text-xs"
                  >
                    <p class="mb-4 sm:h-10" v-html="fig.text"></p>
                    <img v-if="fig.img" :src="fig.img" class="w-full mx-auto max-w-xs mb-4" />
                  </div>
                </div>
              </template>
            </Accordion>
          </div>

          <div class="w-full lg:w-1/2 mb-3">
            <AutoComplete
              v-if="comunes.length"
              v-model="documentCity"
              :values="comunes"
              :label="locale.inputs.document_city"
              :error="v$.documentCity.$error"
              :error-message="Validator.message(v$.documentCity)"
              :clear-incomplete="true"
              test-id="documentCity"
            />

            <Accordion>
              <p class="text-xs pt-2" v-html="locale.inputs.document_help_loc"></p>

              <template #items>
                <div class="my-5 border-y pt-5">
                  <div
                    v-for="(fig, i) in currentLocDocHelpers"
                    :key="i"
                    class="flex flex-col text-xs"
                  >
                    <p class="mb-4 sm:h-10" v-html="fig.text"></p>
                    <img v-if="fig.img" :src="fig.img" class="w-full mx-auto max-w-xs mb-4" />
                  </div>
                </div>
              </template>
            </Accordion>
          </div>

          <div class="w-full lg:w-1/2 lg:pr-4 mb-4">
            <label v-if="identityType == 1" class="text-xs">{{
              locale.release_date
            }}</label>
            <label v-if="identityType == 3" class="text-xs">{{
              locale.release_date_passport_point_7
            }}</label>
            <label v-if="identityType == 4" class="text-xs">{{
              locale.release_date_point_4a
            }}</label>
            <SelectDate
              v-model="documentRelease"
              :start-year="userDate.year ?? 1900"
              :error="v$.documentRelease.$error"
              :error-message="Validator.message(v$.documentRelease)"
              test-id="documentRelease"
            />
          </div>

          <div class="w-full lg:w-1/2 lg:pr-4 mb-4">
            <label v-if="identityType == 1" class="text-xs">{{
              locale.expiration_date
            }}</label>
            <label v-if="identityType == 3" class="text-xs">{{
              locale.expiration_date_passport_point_8
            }}</label>
            <label v-if="identityType == 4" class="text-xs">{{
              locale.expiration_date_point_4b
            }}</label>
            <SelectDate
              v-model="documentExpire"
              :startYear="minYearExpire"
              :end-year="maxYearExpire"
              :error="v$.documentExpire.$error"
              :error-message="Validator.message(v$.documentExpire)"
              test-id="documentExpire"
            />
          </div>
        </template>
      </FormCard>
      <div v-if="identityType">
        <BackButton :event="`goBack`" @go-back="emit('goBack')" />
      </div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
