<script setup lang="ts">
import RadioButton from "~/components/inputs/RadioButton.vue";
import FormCard from "~/components/ui/FormCard.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import BackButton from "~/components/inputs/BackButton.vue";

import Validator from "~/utils/validator";

import { useVuelidate } from "@vuelidate/core";
const locale = await getLocale("forms", "credit_risk")
import creditRiskFormRules from "~/rules/creditRisk";

defineComponent({
  name: "CreditRisk",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const financialProblems = useState<boolean | null>(
  "financialProblems",
  () => null,
);

const v$ = useVuelidate(creditRiskFormRules, { financialProblems });

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div class="w-full mb-8">
          <label class="text-base">{{ locale.text }}</label>
          <div class="flex mt-6 justify-center">
            <RadioButton
              v-model="financialProblems"
              :values="yesNoOptions"
              :error="v$.financialProblems.$error"
              :error-message="Validator.message(v$.financialProblems)"
              data-testid="financialProblems"
            />
          </div>
        </div>
        <template #link>
          <BackButton :event="`goBack`" @goBack="$emit('goBack')"/>
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
