<script setup lang="ts">

import BaseForm from "../ui/BaseForm.vue";
import ProgressBar from "../ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";

defineProps({
  progress: {type: Number, required: true}
})

const emit = defineEmits(["init", "submit", "decline"])
onMounted(() => emit("init"))
</script>

<template>
  <div>
    <BaseForm title="Non perdere questa occasione!">
      <template #upper>
        <ProgressBar :value="progress" />

       <FormCard @submit.prevent="emit('submit')" title="Non perdere questa occasione!">
         <p class="pb-4">
           Cofidis ti regala il primo mese di <strong>accesso gratuito</strong> al CLUB PLUS,
           in cui puoi usufruire di esperienze uniche in formula 2x1: paghi per uno e invita chi vuoi!
           Dopo il mese gratuito, soli 9,90 € al mese. Non lasciarti sfuggire questa occasione!
         </p>
       </FormCard>

        <div class="text-center text-gray-700 mt-4 pb-14">
          <button data-testid="decline" class="underline cursor-pointer" @click="emit('decline')">Procedi senza Club</button>
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped>

</style>
