<template>
  <div class="flex flex-col justify-between pt-12" id="container">
    <main class="flex-1 w-full mx-auto text-center px-6 lg:px-0">
      <div class="bg-white relative rounded-3xl shadow-2xl mx-auto pb-8 sm:max-w-7xl mx-auto">
        <div class="px-6 md:px-12 mx-auto lg:container py-8 text-gray-700">
          <h2 class="md:text-left text-xl md:text-3xl font-semibold">
            I nostri partner commerciali
          </h2>
          <p class="text-base mt-2 md:text-left">Questo elenco comprende i partner commerciali di Cofidis ed è in costante aggiornamento, potrebbe quindi non essere esaustivo.</p>
          <div class="mt-12 md:mt-8">
            <ul>
              <li class="text-left">
                <p class="font-bold">MetLife Europe d.a.c. Rappresentanza Generale per l'Italia</p>
                <p class="mt-1">Sede amministrativa in Via A. Vesalio, 6 - 00161 Roma e sede legale in Irlanda, in ’20 on Hatch’, Lower Hatch Street, Dublino 2</p>
                <p>
                  <a href="https://cofidis-dynamic-res.s3.eu-central-1.amazonaws.com/metlife-informativa-privacy.pdf" target="_blank" class="text-sm underline">Informativa Privacy</a>
                </p>
              </li>
              <li class="text-left mt-10">
                <p class="font-bold">Quattrolinee s.r.l.</p>
                <p class="mt-1">Sede in c.so Vittorio Emanuele II, 25, 10125 – Torino (TO), P. IVA 12671260011</p>
                <p>
                  <a href="https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf" target="_blank" class="text-sm underline">Informativa Privacy</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <footer class="mt-10 md:mt-12 bg-gray-5">
      <div class="mx-auto text-xs text-white py-6 px-4 md:px-0 sm:max-w-7xl mx-auto">
        <div class="text-center md:text-left">
          <p class="mt-4 mb-5 md:my-0">Cofidis Italia<br>&copy; Copyright {{ year }}</p>
          <br>
          <p>Capitale Sociale 67.500.000€ i.v., Iscritta all’Albo Banche ed altri intermediari esteri abilitati in Italia al nr. 8101 - Società soggetta a direzione e coordinamento di Banque Fédérative du Crédit Mutuel S.A. – soggetta alla vigilanza della Banca di Francia (Autorité de Contrôle Prudentiel et de Résolution) e, in qualità di succursale italiana di banca comunitaria, alla vigilanza della Banca d’Italia, per le materie a questa demandate dalle vigenti disposizioni normative. Abilitata all’esercizio dell’attività di intermediazione assicurativa in Italia, iscritta nell’Elenco degli Intermediari dell’Unione Europea annesso al Registro degli Intermediari Assicurativi nr. UE00012402, soggetta a vigilanza dell’ AUTORITÉ DE CONTROLE PRUDENTIEL (ACP).</p>
          <br>
          <p class="">
            <a class="underline" href="https://www.cofidis.it/it/privacy-policy.html" target="_blank">Privacy Policy</a> | <a class="underline" href="https://cofidis-docs-pp.s3.eu-central-1.amazonaws.com/agid.pdf" target="_blank">Accessibilità: non conforme</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
#container {
  min-height: calc(100vh - 72px);
}

main {
  overflow: visible !important;
}

footer {
  background-color: #363636;
}
</style>
