<script setup lang="ts">
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import ContattiDoc from "~/components/icons/ContattiDoc.vue";
import ContattiBank from "~/components/icons/ContattiBank.vue";
import Button from "~/components/inputs/Button.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import SelectedAmount from "~/components/ui/SelectedAmount.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import ResultsWithinMinutes from "~/components/static/ResultsWithinMinutes.vue";

import { useVuelidate } from "@vuelidate/core";
const locale = await getLocale("forms", "contacts")
import contactFormRules from "~/rules/contact";
import Validator from "~/utils/validator";
import LoadingSpinner from "~/components/ui/LoadingSpinner.vue";

defineComponent({
  name: "Contacts",
});

defineProps({
  progress: { type: Number, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const loading = useState<boolean>("loading");

const value = useState<number>("credit", () => 10000);

const email = useState<string | null>("email", () => null);
const cellphone = useState<string | null>("cellphone", () => null);
const name = useState<string | null>("name", () => null);
const surname = useState<string | null>("surname", () => null);
const taxCode = useState<string | null>("taxCode", () => null);

const v$ = useVuelidate(contactFormRules, {
  email,
  cellphone,
  name,
  surname,
  taxCode,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <div class="mx-auto lg:container py-4 leading-tight">
        <form class="form-container" @submit.prevent="submit">
          <SelectedAmount :value="value" :custom-class="'text-2xl'"/>
          <div
            class="flex flex-wrap text-gray-700 p-4 pb-10 px-4 md:px-12 rounded-b-lg"
          >
            <p class="text-center sm:text-left w-full mb-2">
              {{ locale.text1 }}
            </p>

            <div class="flex sm:hidden w-full justify-center mt-2">
              <ResultsWithinMinutes />
            </div>

            <div class="w-full lg:w-1/2 lg:pr-2 mb-2 email-input">
              <InputFloatingLabel
                v-model="email"
                :label="locale.email"
                type="email"
                :disabled="true"
                :error="v$.email.$error"
                :error-message="Validator.message(v$.email)"
              />
            </div>

            <div class="w-full lg:w-1/2 lg:pl-2 mb-2">
              <InputFloatingLabel
                v-model="cellphone"
                :label="locale.cellphone"
                type="tel"
                :disabled="false"
                :error="v$.cellphone.$error"
                :error-message="Validator.message(v$.cellphone)"
                :mask="cellphoneMask"
                test-id="cellphone"
              />
            </div>
            <div class="w-full lg:w-1/2 lg:pr-2 mb-2">
              <InputFloatingLabel
                v-model="name"
                :label="locale.name"
                type="text"
                :mask="alphaMask"
                :disabled="false"
                :error="v$.name.$error"
                :error-message="Validator.message(v$.name)"
                test-id="name"
              />
            </div>
            <div class="w-full lg:w-1/2 lg:pl-2 mb-2">
              <InputFloatingLabel
                v-model="surname"
                :label="locale.surname"
                type="text"
                :mask="alphaMask"
                :disabled="false"
                :error="v$.surname.$error"
                :error-message="Validator.message(v$.surname)"
                test-id="surname"
              />
            </div>
            <div class="w-full lg:w-1/2 lg:pr-2 mb-2">
              <InputFloatingLabel
                v-model="taxCode"
                :label="locale.tax_code"
                type="text"
                :mask="taxCodeMask"
                :error="v$.taxCode.$error"
                :error-message="Validator.message(v$.taxCode)"
                test-id="taxCode"
              />
            </div>
          </div>
          <div class="form-footer text-center -mt-2 mb-4">
            <Button class="!px-16" type="submit" data-testid="contactsSubmit">
              <LoadingSpinner v-if="loading"></LoadingSpinner>
              <span v-else>{{ locale.continue }}</span>
            </Button>
          </div>
        </form>
      </div>
      <div class="spacer h-6"></div>
    </template>
    <template #bottom>
      <div class="px-2 sm:px-16">
        <div class="pt-6 mb-4 text-center sm:text-left">
          {{ locale.required_documents }}
        </div>
        <div class="flex flex-wrap align-center justify-center">
          <div
            class="flex items-center justify-center mb-4 w-full sm:w-1/2 lg:w-1/4 sm:flex-col"
          >
            <ContattiDoc class="h-6 sm:h-12" />

            <span class="pl-2 mt-2 sm:mt-0 text-sm">{{
              locale.valid_identity_document
            }}</span>
          </div>
          <div
            class="flex items-center justify-center mb-4 w-full sm:w-1/2 lg:w-1/4 sm:flex-col"
          >
            <ContattiBank class="h-6 sm:h-12" />
            <span class="pl-2 mt-2 sm:mt-0 text-sm">{{
              locale.iban_current_account
            }}</span>
          </div>
        </div>
        <div class="mt-[-0.25rem] mb-[-1.5rem]">

        <BackButton :event="`goBack`" @goBack="$emit('goBack')"/>
        </div>
      </div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
