<script setup lang="ts">
import BaseForm from "../ui/BaseForm.vue";
import ProgressBar from "../ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";

defineProps({
  progress: {type: Number, required: true}
})

const emit = defineEmits(["init", "submit", "decline"])
onMounted(() => emit("init"))
</script>

<template>
  <div>
    <BaseForm title="CLUB PLUS">
      <template #upper>
        <ProgressBar :value="progress" />

       <FormCard @submit.prevent="emit('submit')" title="CLUB PLUS" :header-class="'!text-base sm:!text-3xl'">
         <p class="mb-4">
           Cofidis ti regala il primo mese di <strong>accesso gratuito al CLUB PLUS.</strong> Puoi scegliere
           <strong>esperienze uniche in formula 2x1</strong>
           (ad esempio cena al ristorante per 2 persone al prezzo di 1 oppure 2 ingressi al cinema o alla SPA pagandone solo 1)
           a soli 9,90 euro/mese, con addebito diretto sul tuo conto corrente. <a class="underline" :href="CLUB_PLUS_INFO_URL" target="_blank">Scopri qui</a> tutti i vantaggi del CLUB PLUS.
         </p>
       </FormCard>

        <div class="text-center text-gray-700 mt-4 mb-8">
          <button data-testid="decline" class="underline cursor-pointer" @click="emit('decline')">Procedi senza Club</button>
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped>

</style>
