<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import Accordion from "~/components/ui/Accordion.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";

defineProps({
  progress: { type: Number, required: true },
});

const emit = defineEmits(["init", "decline", "submit"]);
onMounted(() => emit("init"))
</script>

<template>
  <div>
    <BaseForm>
      <template #upper>
        <ProgressBar :value="progress" />
        <FormCard title="Scegli la polizza facoltativa: 1° mese gratis!" @submit="emit('submit')" :header-class="'!text-gray-4 !text-[1em] sm:!text-3xl'">


          <Accordion class="w-full mb-2">
            <p class="mt-4 text-base">
              Cofidis, in collaborazione con MetLife Italia, ti regala il primo mese* della
              <strong>polizza facoltativa infortuni “MetLife Best Care”</strong>
              che ti tutela in caso di <strong>fratture, lussazioni, ustioni</strong> e prevede una diaria in caso di
              <strong>ricovero e convalescenza. Dopo il primo mese gratuito, potrai continuare ad usufruire della
                copertura al costo mensile di 14,90€,
              </strong> con addebito diretto sul tuo conto corrente da parte di MetLife
              Italia. Per maggiori informazioni
              <a href="javascript:void(0);" class="underline clickable">
                clicca qui
              </a>.
            </p>

            <template #items>
              <div class="text-gray-700 border-t mt-5 pt-5 flex flex-wrap pb-4">
                <div class="w-full md:w-1/3">
                  <h3 class="font-semibold mb-4">
                    Ti diamo qualche informazione in più sulla polizza “MetLife Best Care”.
                    Si tratta di una copertura assicurativa infortuni facoltativa che ti garantisce:
                  </h3>
                </div>
                <div class="w-full md:w-1/3">
                  <ul class="list-disc px-6 text-sm">
                    <li>una somma pari a 100€/giorno in caso di ricovero a seguito di infortunio</li>
                    <li>una somma pari a 50€/giorno in caso di convalescenza post ricovero a seguito di infortunio</li>
                    <li>una somma pari a 100€/giorno in caso di ricovero in reparto di terapia intensiva</li>
                    <li>una somma pari a 500€ in caso di frattura ossea, lussazione o ustione a seguito di infortunio.</li>
                  </ul>
                </div>
                <div class="w-full md:w-1/3">
                  <span class="block pb-4 text-sm">La polizza dura un anno e si rinnova tacitamente fino ai 69 anni di età, salvo disdetta che potrai esercitare in qualsiasi momento.</span>
                </div>
              </div>
            </template>
          </Accordion>

          <p>
            <strong>La polizza è facoltativa e non è in alcun modo collegata al finanziamento, che è ancora in corso di
              valutazione</strong>
            e di cui l’esito ti sarà comunicato al termine della richiesta di finanziamento, in quanto
            <strong>la polizza non costituisce presupposto necessario per ottenerlo o per ottenerlo a condizioni
              diverse</strong>.
          </p>
          <p class="mt-4">
            MetLife ti regala inoltre l'accesso a una piattaforma esclusiva con un mondo di sconti e vantaggi per il
            benessere tuo, di chi ami e dell'ambiente.
          </p>
          <p class="my-4">
            Prima di proseguire, ti invitiamo a prendere visione del <a class="underline" :href="METLIFE_INFO_URL"
                                                                        target="_blank">set informativo</a>, che riporta
            nel dettaglio le caratteristiche del prodotto, le <a
              href="https://cofidis-dynamic-res.s3.eu-central-1.amazonaws.com/metlife-best-care-esclusioni.pdf"
              target="_blank" class="underline">limitazioni ed esclusioni</a>, le modalità per <a
              href="https://cofidis-dynamic-res.s3.eu-central-1.amazonaws.com/metlife-best-care-recesso.pdf"
              target="_blank" class="underline">recedere dalla polizza</a> nei primi 60 giorni, nonché l’<a
              target="_blank" class="underline" :href="METLIFE_PRIVACY_URL">informativa privacy</a>.
          </p>

          <template #link>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
              <span @click="emit('decline')" class="underline uppercase cursor-pointer" data-testid="decline">Continua senza polizza</span>
            </div>
          </template>
        </FormCard>
      </template>
    </BaseForm>
  </div>
</template>


<style scoped>

</style>
