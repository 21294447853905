<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import PromoBox from "~/components/ui/PromoBox.vue";
const locale = await getLocale("forms", "promo")
import type {IRate} from "~/interfaces";
import { getPromoUpgradeModel } from "~/utils/promo";

const props = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  ctaText: { type: String, default: "CONTINUA" },
  hasDetails: { type: Boolean, required: true },
  hasInsurance: { type: Boolean, required: true },
  hasService: { type: Boolean, required: true },
});

const emit = defineEmits(["init", "submit", "decline"]);

const loading = ref(false);
const model = getPromoUpgradeModel()!;

const detailsHtml = computed(() => {
  let html = locale.details_initial_text.replace(
    "%d",
    model.imp_rata.toString(),
  );
  if (props.hasInsurance && props.hasService) {
    html += locale.details_when_has_insurance_and_service
      .replace("%insurance", model.insurance?.toString() ?? "")
        .replace("%club_rata", model.club_rata ? model.club_rata.toString() : "")
      .replace("%dovuto", model.dovuto.toString());
  } else if (props.hasInsurance) {
    html += locale.details_when_has_only_insurance
      .replace("%insurance", model.insurance?.toString() ?? "")
      .replace("%dovuto", model.dovuto.toString());
  } else if (props.hasService) {
    html += locale.details_when_has_only_service
        .replace("%club_rata", model.club_rata ? model.club_rata.toString() : "")
      .replace("%dovuto", model.dovuto.toString());
  }
  return html;
});

const submit = (model: IRate) => {
  loading.value = true;
  emit("submit", model);
  setTimeout(() => {
    loading.value = false;
  })
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm class="relative">
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" :has-submit="false">
        <div class="w-full mb-6">
          <p class="text-base text-center sm:text-left">{{ locale.text }}</p>
          <div class="mt-14">
            <PromoBox
              :model="model"
              :has-details="hasDetails"
              :loading="loading"
              :cta="ctaText"
              data-testid="promo"
              @click="submit(model)"
            >
              <template #details>
                <span v-html="detailsHtml"></span>
              </template>
            </PromoBox>
          </div>
          <div class="text-center text-gray-700 mt-16">
            <button
              class="underline text-sm md:text-base"
              data-testid="decline"
              @click="emit('decline')"
            >
              {{ locale.or_confirm_previous_choice }}
            </button>
          </div>
        </div>
      </FormCard>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped>
div :deep(.form-body) {
  @apply p-0 pb-6 sm:px-6 md:px-8;
}
div :deep(.form-footer) {
  display: none !important;
}
</style>
