<script setup lang="ts">
import TypFb from "~/components/icons/typ/typ-fb.vue";
import TypIg from "~/components/icons/typ/typ-ig.vue";
import TypSmile from "~/components/icons/typ/typ-smile.vue";
import TypEnvelope from "~/components/icons/typ/typ-envelope.vue";
import SovendusWidget from "~/components/static/SovendusWidget.vue";

const locale = await getLocale("typ", "typ_cq_metlife")
const emit = defineEmits(["init"]);

defineComponent({
  name: "TypCQMetlife",
});

defineProps({
  name: {
    type: String,
    required: true,
  },
});
onMounted(() => emit("init"));
</script>

<template>
  <div class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-4 mb-10 lg:my-10">
    <div class="mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700">
      <h3 class="px-6 py-6 text-center text-2xl sm:text-3xl bg-orange font-semibold" v-html="locale.header.replace('%d', name)"></h3>
      <h1 class="text-xl sm:text-2xl font-semibold text-center sm:mt-4 mb-6 sm:mb-8 uppercase bg-yellow py-2" v-html="locale.title"></h1>
      <div class="max-w-4xl text-lg mx-auto py-4 px-6">
        <div class="flex mb-6 items-center">
          <div class="w-1/5 text-center">
            <TypEnvelope class="h-12 mx-auto" />
          </div>
          <p class="w-4/5 pl-4 sm:pl-0 text-sm" v-html="locale.instructions.first"></p>
        </div>

        <div class="flex mb-6 items-center">
          <div class="w-1/5 text-center">
            <TypSmile class="h-12 mx-auto" />
          </div>
          <p class="w-4/5 pl-4 sm:pl-0 text-sm" v-html="locale.instructions.second"></p>
        </div>

        <p class="text-center mt-10" v-html="locale.thanks"></p>
        <div class="text-center mt-10">
          <p v-html="locale.follow_us"></p>
          <div class="social-links">
            <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.facebook.com/CofidisItalia/">
              <TypFb class="h-12 mx-auto" />
            </a>
            <a class="inline-block social-link p-4" target="_blank" rel="nofollow" href="https://www.instagram.com/cofidisitalia/">
              <TypIg class="h-12 mx-auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer h-6"></div>
    <SovendusWidget />
  </div>
</template>

<style scoped>

</style>
