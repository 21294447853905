<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import PromoBox from "~/components/ui/PromoBox.vue";
const locale = await getLocale("forms", "promo_confirm")
import type { IRate } from "~/interfaces";
import { getPromoModel, getPromoUpgradeModel } from "~/utils/promo";

const props = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  cta: { type: String, default: "CONTINUA" },
  hasDetails: { type: Boolean, required: true },
  hasInsurance: { type: Boolean, required: true },
  hasService: { type: Boolean, required: true },
});

const promoModel = getPromoModel()!;
const promoUpgradeModel = getPromoUpgradeModel()!;

const emit = defineEmits(["init", "submit", "decline"]);

const showUpgrade = ref(true);
const loading = ref(false);

const detailsHtml = (upgrade = false) => {
  const model = upgrade ? promoUpgradeModel : promoModel;
  let html = locale.details_initial_text.replace(
    "%d",
    model.imp_rata.toString(),
  );
  if (props.hasInsurance && props.hasService) {
    html += locale.details_when_has_insurance_and_service
      .replace("%insurance", model.insurance?.toString() ?? "")
        .replace("%club_rata", model.club_rata ? model.club_rata.toString() : "")
      .replace("%dovuto", model.dovuto.toString());
  } else if (props.hasInsurance) {
    html += locale.details_when_has_only_insurance
      .replace("%insurance", model.insurance?.toString() ?? "")
      .replace("%dovuto", model.dovuto.toString());
  } else if (props.hasService) {
    html += locale.details_when_has_only_service
        .replace("%club_rata", model.club_rata ? model.club_rata.toString() : "")
      .replace("%dovuto", model.dovuto.toString());
  }
  return html;
};

const submit = (upgrade = false, model: IRate) => {
  loading.value = true;
  emit("submit", upgrade, model);
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" :has-submit="false">
        <div class="w-full mt-12 mb-8">
          <PromoBox
            v-if="promoModel"
            :model="promoModel"
            :has-details="hasDetails"
            :loading="loading"
            :collapsed="showUpgrade"
            :show-flag="false"
            :cta="cta"
            data-testid="promo"
            @expand="showUpgrade = !showUpgrade"
            @click="submit(false, promoModel)"
          >
            <template #details>
              <div class="text-justify" v-html="detailsHtml(false)"></div>
            </template>
          </PromoBox>
          <p
            class="text-base text-center"
            :class="showUpgrade ? 'mt-8 mb-12' : 'mt-16 mb-10'"
          >
            {{ locale.or_request_more_liquidity }}
          </p>
          <PromoBox
            v-if="promoUpgradeModel"
            :model="promoUpgradeModel"
            :has-details="hasDetails"
            :loading="loading"
            :collapsed="!showUpgrade"
            :cta="cta"
            data-testid="promoUpgrade"
            @expand="showUpgrade = !showUpgrade"
            @click="submit(true, promoUpgradeModel)"
          >
            <template #details>
              <span v-html="detailsHtml(true)"></span>
            </template>
          </PromoBox>
        </div>
      </FormCard>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped>
div :deep(.form-body) {
  @apply p-0 pb-6 sm:px-6 md:px-8;
}
div :deep(.form-footer) {
  display: none !important;
}
</style>
