<script setup lang="ts">
import Autocomplete from "js-autocomplete";
import type { IValue } from "~/interfaces";
import Fuse from "fuse.js";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

defineComponent({
  name: "AutoComplete",
});

const { values, delimiter, clearIncomplete } = defineProps({
  name: { type: String, required: false },
  label: { type: String, default: "", required: true },
  disabled: { type: Boolean, default: false },
  delimiter: { type: String, default: null, required: false },
  error: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
  values: { type: Array as () => IValue[], default: () => [], required: true },
  clearIncomplete: { type: Boolean, default: false, required: false },
  uppercase: { type: Boolean, default: true },
  testId: { type: String, default: "" },
  type: { type: String, default: "text" },
});
const value = defineModel({
  type: String,
  default: "",
});

const elementsToSearch = new Fuse(values, {
  keys: ["label"],
  threshold: 0.0,
  location: 0,
  distance: 0,
  minMatchCharLength: 0,
  isCaseSensitive: false,
});

const inputRef = ref<HTMLInputElement | null>(null);
const match = ref<boolean>(true);
const select = ref<boolean>(true);

defineEmits(["input"]);

onMounted(() => {
  if (inputRef.value) {
    inputRef.value.value = value.value;
    const engine = new Autocomplete({
      selector: inputRef.value,
      minChars: 2,
      delay: 200,
      cache: false,
      source: (term: string, response: (data: string[]) => void) => {
        select.value = false;
        response(search(term));
      },
      onSelect: (e: Event, term: string) => {
        select.value = true;
        value.value = term;
      },
    });
    onBeforeUnmount(() => {
      engine.destroy();
    });
  }
});

const handleBlur = (e: FocusEvent) => {
  if (e.target instanceof HTMLInputElement) {
    if (e.target.value !== value.value) {
      value.value = "";
      e.target.value = "";
    }
    if (clearIncomplete) {
      if (!match.value || !select.value) {
        value.value = "";
        e.target.value = "";
      }
    }
  }
};

const search = (term: string): string[] => {
  match.value = true;

  const searchAndMapResults = (
    searchTerm: string,
    mapFn: (result: any) => string,
  ): string[] => {
    const results = elementsToSearch.search(searchTerm);

    if (clearIncomplete) {
      if (results.length === 0) {
        match.value = false;
      }
    }

    return results.map(mapFn);
  };

  if (delimiter != null) {
    if (!term.includes(delimiter)) return [];

    const [name, domain] = term.split(delimiter);

    if (!domain) {
      return (elementsToSearch._docs as IValue[]).map(
        (result: IValue) => `${name}${delimiter}${result.value}`,
      );
    }

    return searchAndMapResults(
      domain,
      (result: any) => `${name}${delimiter}${result.item.value}`,
    );
  }

  if (term.length < 2) {
    return [];
  }

  return searchAndMapResults(term, (result: any) => result.item.label);
};

const formatInput = () => {
  value.value = inputRef.value?.value.replace(/’/g, "'") ?? ''
}

onUnmounted(() => {
  if (inputRef.value) {
    inputRef.value.value = "";
  }
});
</script>

<template>
  <div class="label-floating" :class="{ 'border-red': error }">
    <input
      ref="inputRef"
      v-model="value"
      :type="type"
      :placeholder="label"
      :disabled="disabled"
      :class="['autocomplete-input primary', uppercase ? 'uppercase' : '']"
      @blur="handleBlur"
      @input="formatInput"
    />
    <label>{{ label }}</label>
  </div>
  <ErrorMessage v-if="error" :message="errorMessage" />
</template>

<style scoped>
.autocomplete-input {
  @apply sm:h-12 placeholder:normal-case;
}
</style>
