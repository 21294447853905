<script setup lang="ts">
import PromoboxFlag from "~/components/icons/PromoboxFlag.vue";
import Accordion from "~/components/ui/Accordion.vue";
import Button from "~/components/inputs/Button.vue";
import Info from "~/components/icons/Info.vue";
import type { IRate } from "~/interfaces";
const locale = await getLocale("ui", "promo_box")
import SelectArrowDown from "~/components/icons/SelectArrowDown.vue";
import LoadingSpinner from "~/components/ui/LoadingSpinner.vue";

const props = defineProps({
  model: { type: Object as () => IRate, required: true },
  hasDetails: { type: Boolean, required: true },
  loading: { type: Boolean, required: true },
  cta: { type: String, default: "CONTINUA" },
  collapsed: { type: Boolean, default: false },
  showFlag: { type: Boolean, default: true },
  flagText: { type: String, default: "Più liquidità per i tuoi progetti" },
});

const emit = defineEmits(["expand", "click"]);

const promoInfo = ref<any>();

const toggleAccordion = () => {
  promoInfo.value.toggleAccordion();
};

const collapsedText = computed(() => {
  return locale.collapsed_text
    .replace("%imp_finanz_text", props.model.imp_finanz_text ?? "")
    .replace("%tot_rata", props.model.tot_rata?.toString() ?? "")
    .replace("%rate", props.model?.rate.toString())
    .replace("%taeg", props.model?.taeg.toString());
});

const openedText = computed(() => {
  return locale.opened_text
    .replace("%tot_rata", props.model.tot_rata?.toString() ?? "")
    .replace("%rate", props.model?.rate.toString())
    .replace("%taeg", props.model?.taeg.toString());
});
</script>

<template>
  <div
    v-if="collapsed"
    class="rounded-full py-1 px-6 border border-gray-3 w-full lg:w-1/2 mx-auto relative cursor-pointer xs:text-xs sm:text-lg"
    @click="emit('expand')"
  >
    <p v-html="collapsedText"></p>
    <SelectArrowDown />
  </div>
  <div
    v-else
    class="promobox rounded-2xl border border-yellow px-4 sm:px-6 pb-6 w-full lg:w-1/2 mx-auto relative text-base sm:text-lg lg:text-xl"
    :class="showFlag ? ' pt-5' : 'pt-1'"
  >
    <template v-if="showFlag">
      <PromoboxFlag />
      <span
        class="promobox-flag-text absolute text-white -top-2 -left-3 italic font-semibold text-sm leading-none px-3 pb-0.5 pl-5 tracking-wide"
      >
        {{ flagText }}
      </span>
    </template>
    <div
      class="flex flex-wrap border-b border-gray-3 w-full pb-3"
      :class="showFlag ? 'pt-1' : 'pt-3'"
    >
      <div class="flex-none whitespace-no-wrap font-semibold text-lg">
        {{ model.imp_finanz_text }}
      </div>
      <div class="flex-grow"></div>
      <div class="flex-none">
        <div class="flex-none">
          <a
            class="sm:text-base underline mr-4"
            target="_blank"
            :href="model.secci_url!"
            >SECCI</a
          >
        </div>
      </div>
    </div>
    <div class="flex flex-wrap w-full py-3 xs:text-sm ipp:text-base sm:text-lg">
      <p class="flex-none" v-html="openedText"></p>
      <div class="flex-grow"></div>
      <div
        v-if="hasDetails"
        class="flex-none sm:pt-1 cursor-pointer"
        @click="toggleAccordion"
      >
        <Info />
      </div>
    </div>
    <Accordion ref="promoInfo">
      <template #items>
        <small class="block text-sm leading-normal md:text-base pb-3">
          <slot name="details"></slot>
        </small>
      </template>
    </Accordion>
    <Button
      class="w-3/4 sm:w-auto px-12 font-semibold shadow-xl z-100 text-sm absolute -bottom-6 left-1/2 transform -translate-x-1/2"
      @click="emit('click')"
    >
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <span v-else>{{ cta }}</span>
    </Button>
  </div>
</template>

<style scoped></style>
