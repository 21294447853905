<script setup>
    defineComponent({name: "SovendusWidget"})

    const trafficSourceNumber = 8111
    const trafficMediumNumber = 1
    const mobileDelayTime = 3000
    const scriptId = 'sovendus-integration-script'
    const scriptSrc = 'https://api.sovendus.com/sovabo/common/js/flexibleIframe.js'

    const { id } = defineProps({
        id: {type: Number, default: 1, required: false}
    })

    const sovDivId = computed(() => {
        return `sovendus-integration-container-${id}`
    })

    const createWidget = () => {
        window.sovIframes = window.sovIframes || []
        window.sovIframes.push({
            trafficSourceNumber: trafficSourceNumber,
            trafficMediumNumber: trafficMediumNumber,
            iframeContainerId: sovDivId.value,
            integrationType: "vue-1.0.9"
        })
        const script = document.createElement('script')
        script.async = true
        script.id = scriptId
        script.src = scriptSrc
        script.type = 'text/javascript'
        document.body.appendChild(script)

        // on mobile show the widget with a delay
        setTimeout(() => {
            document.querySelector('.sov-overlay')?.classList.add('visible')
        }, mobileDelayTime)
    }

    const handleRouteLeave = () => {
        window.sovIframes = []
        document.getElementById('sovloader-script')?.remove()
        document.getElementById(scriptId)?.remove()
    }

    onMounted(createWidget)
    onBeforeUnmount(handleRouteLeave)
    onBeforeRouteLeave(handleRouteLeave)

</script>

<template>
    <div :id="sovDivId"></div>
</template>

<style>
@media (max-width: 678px) {
    .sov-overlay {
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    .sov-overlay.visible {
        opacity: 1;
        pointer-events: all;
    }
}
</style>