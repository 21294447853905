<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import Select from "~/components/inputs/Select.vue";
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import SelectDate from "~/components/inputs/SelectDate.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import employmentTypes from "~/data/employment_types.json";
import allEmploymentSectors from "~/data/employment_sectors.json";
import allOccupations from "~/data/occupations.json";
import monthlyPayments from "~/data/monthly_payments.json";
import hiringTypes from "~/data/hiring_types.json";

import Validator from "~/utils/validator";

import type { IValue } from "~/interfaces";

const locale = await getLocale("forms", "income_data")
import { useVuelidate } from "@vuelidate/core";
import incomeFormRules from "~/rules/incomeData";


defineComponent({
  name: "IncomeData",
});

const { insurance } = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  employedSinceStartYear: { type: Number, required: true },
  insurance: { type: Function, default: false }
});

const emit = defineEmits(["init", "submit", "goBack"]);

const employmentType = useState<null | number>("employmentType", () => null);
const employmentSector = useState<null | number>(
  "employmentSector",
  () => null,
);
const monthlyPayment = useState<null | number>("monthlyPayment", () => null);
const occupation = useState<null | number>("occupation", () => null);
const hiringType = useState<null | number>("hiringType", () => null);
const monthlyIncome = useState<string | null>("monthlyIncome", () => null);
const employedSince = useState<string | null>("employedSince", () => null);
const employedTo = useState<string | null>("employedTo", () => null);
const familyMemberCount = useState<null | number>(
  "familyMemberCount",
  () => null,
);
const employedFamilyMemberCount = useState<null | number>(
  "employedFamilyMemberCount",
  () => null,
);
const employmentSectors = computed(() => {
  return employmentType.value
    ? allEmploymentSectors[
        employmentType.value.toString() as keyof typeof allEmploymentSectors
      ]
    : [];
});

const occupations = computed(() => {
  return employmentType.value && employmentSector.value
    ? allOccupations[
        employmentType.value.toString() as keyof typeof allOccupations
      ]?.[employmentSector.value.toString() as keyof typeof allOccupations]
    : [];
});

const familyMembersCounts = computed(() => {
  const arr: IValue[] = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => ({
    value: i,
    label: i.toString(),
  }));
  arr.unshift({
    value: null,
    label: locale.family_members_count,
    disabled: true,
  });
  return arr;
});

const employedFamilyMemberCounts = computed(() => {
  const arr: IValue[] = [
    {
      value: null,
      label: locale.employed_family_members_count,
      disabled: true,
    },
  ];
  if (familyMemberCount.value) {
    for (let i = 1; i <= familyMemberCount.value; i++) {
      arr.push({ value: i, label: i.toString() });
    }
  }
  return arr;
});

const showEmploymentSector = computed(() => {
  return employmentSectors.value?.length;
});

const showMonthlyPayment = computed(() => {
  if (employmentType.value) {
    return (
      [
        EMPLOYMENT_TYPES.PENSIONATO,
        EMPLOYMENT_TYPES.PENSIONATO_INVALIDO,
      ].includes(employmentType.value) ||
      (employmentType.value == EMPLOYMENT_TYPES.DIPENDENTE && occupation.value)
    );
  }
  return false;
});

const showHiringType = computed(() => {
  return (
    employmentType.value === EMPLOYMENT_TYPES.DIPENDENTE && occupation.value
  );
});

const showOccupations = computed(() => {
  return occupations.value?.length;
});

const showMonthlyIncome = computed(() => {
  if (employmentType.value) {
    return (
      [
        EMPLOYMENT_TYPES.PENSIONATO,
        EMPLOYMENT_TYPES.PENSIONATO_INVALIDO,
        EMPLOYMENT_TYPES.REDDITIERE,
      ].includes(employmentType.value) ||
      (employmentType.value === EMPLOYMENT_TYPES.PROFESSIONISTA &&
        employmentSector.value) ||
      occupation.value
    );
  }
  return false;
});

const showEmployedSince = computed(() => {
  if (employmentType.value) {
    return (
      ([
        EMPLOYMENT_TYPES.AUTONOMO,
        EMPLOYMENT_TYPES.DIPENDENTE,
        EMPLOYMENT_TYPES.STAGIONALE,
      ].includes(employmentType.value) &&
        occupation.value) ||
      (employmentType.value === EMPLOYMENT_TYPES.PROFESSIONISTA &&
        employmentSector.value)
    );
  }
  return false;
});

const showEmployedTo = computed(() => {
  return (
    (employmentType.value === EMPLOYMENT_TYPES.STAGIONALE ||
      hiringType.value === HIRING_TYPES.DETERMINATO) &&
    occupation.value
  );
});

const employedToStartYear = computed(() => {
  return new Date().getFullYear();
});

const employedToEndYear = computed(() => {
  return new Date().getFullYear() + 20;
});

const showFamilyMember = computed(() => {
  if (userPath() == USER_PATHS.FE && employmentType.value) {
    return [EMPLOYMENT_TYPES.PENSIONATO, EMPLOYMENT_TYPES.REDDITIERE, EMPLOYMENT_TYPES.PENSIONATO_INVALIDO].includes(
      employmentType.value,
    );
  }
  return false;
});

watch(employmentType, async () => {
  employmentSector.value = null;
  occupation.value = null;
  familyMemberCount.value = null;
  employedFamilyMemberCount.value = null;
  monthlyPayment.value = null;
  monthlyIncome.value = "";
  employedSince.value = "";
  employedTo.value = "";
  hiringType.value = null;
});

watch(employmentSector, async () => {
  occupation.value = null;
});

watch(hiringType, async () => {
  employedTo.value = "";
});

watch(monthlyIncome, async (newValue) => {
  if (newValue && showFamilyMember) {
    familyMemberCount.value = null;
    employedFamilyMemberCount.value = null;
  }
});

watch(familyMemberCount, async (newValue) => {
  if (newValue === 1) {
    employedFamilyMemberCount.value = 1;
    return;
  }
  if (
    newValue &&
    employedFamilyMemberCount.value &&
    newValue < employedFamilyMemberCount.value
  ) {
    employedFamilyMemberCount.value = newValue;
  }
});

const v$ = useVuelidate(incomeFormRules, {
  employmentType,
  employmentSector,
  monthlyPayment,
  occupation,
  hiringType,
  monthlyIncome,
  employedSince,
  employedTo,
  familyMemberCount,
  employedFamilyMemberCount,
  showEmploymentSector,
  showMonthlyPayment,
  showOccupations,
  showHiringType,
  showMonthlyIncome,
  showEmployedSince,
  showEmployedTo,
  showFamilyMember,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => {
  emit("init");

  updateStateValue("familyIncome", null);
  updateStateValue("familyIncomeActivity", null);
})
</script>

<template>
  <BaseForm class="income-data-container">
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <Select
            v-model="employmentType"
            :values="employmentTypes"
            :label="locale.employment_type"
            floating
            disable-zero-value
            :error="v$.employmentType.$error"
            :error-message="Validator.message(v$.employmentType)"
            test-id="employmentType"
            @change="v$.$reset()"
          />
        </div>
        <div v-if="showEmploymentSector" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <Select
            v-model="employmentSector"
            :values="employmentSectors"
            :label="locale.employment_sector"
            floating
            disable-zero-value
            :error="v$.employmentSector.$error"
            :error-message="Validator.message(v$.employmentSector)"
            test-id="employmentSector"
            @change="v$.$reset()"
          />
        </div>
        <div
          v-if="showMonthlyPayment && !showHiringType"
          class="w-full lg:w-1/3 lg:pr-4 mb-2"
        >
          <Select
            v-model="monthlyPayment"
            :values="monthlyPayments"
            :label="locale.monthly_payment"
            floating
            disable-zero-value
            :error="v$.monthlyPayment.$error"
            :error-message="Validator.message(v$.monthlyPayment)"
            test-id="monthlyPayment"
            @change="v$.$reset()"
          />
        </div>
        <div v-if="showOccupations" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <Select
            v-model="occupation"
            :values="occupations"
            :label="locale.current_employment"
            floating
            disable-zero-value
            :error="v$.occupation.$error"
            :error-message="Validator.message(v$.occupation)"
            test-id="occupation"
            @change="v$.$reset()"
          />
        </div>
        <div class="w-full"></div>
        <div v-if="showHiringType" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <Select
            v-model="hiringType"
            :values="hiringTypes"
            :label="locale.hiring_type"
            floating
            disable-zero-value
            :error="v$.hiringType.$error"
            :error-message="Validator.message(v$.hiringType)"
            test-id="hiringType"
            @change="v$.$reset()"
          />
        </div>
        <div
          v-if="showMonthlyPayment && showHiringType"
          class="w-full lg:w-1/3 lg:pr-4 mb-2"
        >
          <Select
            v-model="monthlyPayment"
            :values="monthlyPayments"
            :label="locale.monthly_payment"
            floating
            disable-zero-value
            :error="v$.monthlyPayment.$error"
            :error-message="Validator.message(v$.monthlyPayment)"
            test-id="monthlyPayment"
          />
        </div>
        <div class="w-full"></div>
        <template v-if="showMonthlyIncome">
          <div
            class="w-full lg:w-1/3 lg:pr-4 mb-2"
            :class="{ 'lg:mt-6': showEmployedSince }"
          >
            <InputFloatingLabel
              v-model="monthlyIncome"
              :label="locale.monthly_income"
              type="tel"
              :mask="currencyMask"
              :error="v$.monthlyIncome.$error"
              :error-message="Validator.message(v$.monthlyIncome)"
              test-id="monthlyIncome"
            />
          </div>
        </template>
        <div v-if="showEmployedSince" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <label class="text-xs">{{ locale.employment_since }}</label>
          <SelectDate
            v-model="employedSince"
            :show-day="false"
            :start-year="employedSinceStartYear"
            :error="v$.employedSince.$error"
            :error-message="Validator.message(v$.employedSince)"
            test-id="employedSince"
          />
        </div>
        <div v-if="showEmployedTo" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <label class="text-xs">{{ locale.employment_to }}</label>
          <SelectDate
            v-model="employedTo"
            :show-day="false"
            :start-year="employedToStartYear"
            :end-year="employedToEndYear"
            :error="v$.employedTo.$error"
            :error-message="Validator.message(v$.employedTo)"
            test-id="employedTo"
          />
        </div>

        <div v-if="showFamilyMember" class="w-full lg:w-1/3 lg:pr-4 mb-2">
          <Select
            v-model="familyMemberCount"
            :values="familyMembersCounts"
            :label="locale.family_members_count"
            floating
            disable-zero-value
            :error="v$.familyMemberCount.$error"
            :error-message="Validator.message(v$.familyMemberCount)"
            test-id="familyMemberCount"
          />
        </div>
        <div
          v-if="showFamilyMember && familyMemberCount"
          class="w-full lg:w-1/3 lg:pr-4 mb-2"
        >
          <Select
            v-model="employedFamilyMemberCount"
            :values="employedFamilyMemberCounts"
            :label="locale.employed_family_members_count"
            floating
            disable-zero-value
            :error="v$.employedFamilyMemberCount.$error"
            :error-message="Validator.message(v$.employedFamilyMemberCount)"
            test-id="employedFamilyMemberCount"
          />
        </div>


        <div v-if="insurance()" class="w-full mb-4 text-xs">
          {{ locale.insurance_available_text }}
        </div>
        <template #link>
          <BackButton :event="`goBack`" @goBack="$emit('goBack')"/>
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
