import { helpers, required, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import { DateTime } from "luxon";

export default {
  citizenship: {
    required: helpers.withMessage(validationJson.required, required),
  },
  maritalStatus: {
    required: helpers.withMessage(validationJson.required, required),
  },
  stayFrom: {
    maxValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showStayFrom }) => {
        if (!showStayFrom.value) return true;
        return DateTime.fromFormat(value, "MM/yyyy") <=  DateTime.now();
      },
    ),
  },
  stayTo: {
    minValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showStayTo }) => {
        if (!showStayTo.value) return true;
        return DateTime.fromFormat(value, "dd/MM/yyyy") >= DateTime.now();
      },
    ),
    maxValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showStayTo }) => {
        if (!showStayTo.value) return true;
        return DateTime.fromFormat(value, "dd/MM/yyyy") <= DateTime.now().plus({ years: 10 });
      },
    ),
  },
};
