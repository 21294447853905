<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.935"
    height="60"
    viewBox="0 0 59.935 60"
  >
    <g
      id="Raggruppa_8334"
      data-name="Raggruppa 8334"
      transform="translate(-1976.232 694.488)"
    >
      <path
        id="Tracciato_7907"
        data-name="Tracciato 7907"
        d="M1976.232-662.609v-3.749c.19-1.31.328-2.629.577-3.927a30.018,30.018,0,0,1,26.475-24.055,28.489,28.489,0,0,1,17.986,3.94c10.739,6.62,15.716,16.338,14.788,28.92a27.968,27.968,0,0,1-6.539,15.885,29.532,29.532,0,0,1-18.059,10.643c-1.109.2-2.234.312-3.352.465h-3.75c-.732-.09-1.466-.168-2.2-.272a29.424,29.424,0,0,1-14.827-6.448,29.545,29.545,0,0,1-10.636-18.049C1976.5-660.366,1976.385-661.491,1976.232-662.609Zm12.683-1.858c.108,2.849.112,5.725.35,8.58a8.84,8.84,0,0,0,7.729,8.252,46.97,46.97,0,0,0,6.6.348c3.728,0,7.465.017,11.179-.246a8.655,8.655,0,0,0,7.581-5.2,10.619,10.619,0,0,0,.98-4.427q.092-6.061.1-12.124a37.138,37.138,0,0,0-.216-3.8,8.914,8.914,0,0,0-7.747-8.247,45.706,45.706,0,0,0-6.539-.347c-3.747,0-7.5-.018-11.238.239a8.913,8.913,0,0,0-8.432,8.411C1989.024-670.193,1989.023-667.338,1988.915-664.467Z"
        fill="#363636"
      />
      <path
        id="Tracciato_7908"
        data-name="Tracciato 7908"
        d="M2124.033-559.639c2.183.062,4.368.071,6.547.2a4.994,4.994,0,0,1,5.086,5.082q.173,6.519,0,13.046a4.942,4.942,0,0,1-5.132,5.126q-6.5.177-13,0a4.93,4.93,0,0,1-5.11-5.093q-.175-6.519,0-13.046c.082-3.039,2.066-4.905,5.1-5.122,1.088-.078,2.181-.1,3.272-.124,1.075-.02,2.151,0,3.227,0Zm7.395,11.83a7.367,7.367,0,0,0-7.36-7.375,7.37,7.37,0,0,0-7.4,7.382,7.373,7.373,0,0,0,7.361,7.376A7.371,7.371,0,0,0,2131.428-547.809Zm2.016-7.626a1.714,1.714,0,0,0-1.648-1.764,1.706,1.706,0,0,0-1.787,1.671,1.706,1.706,0,0,0,1.645,1.763A1.711,1.711,0,0,0,2133.444-555.435Z"
        transform="translate(-117.811 -116.701)"
        fill="#363636"
      />
      <path
        id="Tracciato_7909"
        data-name="Tracciato 7909"
        d="M2192.479-483a4.8,4.8,0,0,1-4.777,4.8,4.8,4.8,0,0,1-4.808-4.814,4.8,4.8,0,0,1,4.8-4.771A4.8,4.8,0,0,1,2192.479-483Z"
        transform="translate(-181.452 -181.483)"
        fill="#363636"
      />
    </g>
  </svg>
</template>

<style scoped></style>
