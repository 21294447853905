import { helpers, required, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  employerName: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employerAddress: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employerStreetNumber: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employerCity: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employerPostalCode: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employerPhone: {
    required: helpers.withMessage(validationJson.required, required),
    validPhone: helpers.withMessage(
      validationJson.invalid_phone,
      Validator.phone,
    ),
  },
  employeeLoan: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { showEmployeeLoan }: any) {
        return !!(showEmployeeLoan ?? false);
      }),
    ),
  },
  familyMemberCount: {
    required: helpers.withMessage(
        validationJson.required,
        requiredIf(function(value: number, { showFamilyMemberCount } : any){
          return showFamilyMemberCount
        })
    ),
    nonZero: Validator.nonZeroIf("showFamilyMemberCount"),
  },
  employedFamilyMemberCount: {
    required: helpers.withMessage(
        validationJson.required,
        requiredIf(function(value: number, { familyMemberCount } : any){
          return familyMemberCount.value > 1
        })
    ),
    nonZero: Validator.nonZeroIf("showFamilyMemberCount"),
  },
};
