<script setup lang="ts">
const locale = await getLocale("inputs", "back_button")

const props = defineProps({
  event: { type: String, required: true },
  class: {
    type: String,
    required: false,
    default: "",
  },
});
</script>

<template>
  <div class="text-center text-sm text-gray-700 mb-12 mt-4">
    <a :class="`${props.class} back-btn underline cursor-pointer`" data-testid="goBack" @click.prevent="$emit(props.event)">{{
      locale.label
    }}</a>
  </div>
</template>

<style scoped></style>
