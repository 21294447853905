<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
const locale = await getLocale("forms", "loan_protection_warning")

defineComponent({
  name: "LoanProtectionWarning",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  package: { type: String, required: true },
  rate: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "decline"]);
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="emit('submit')">
        <p class="text-red font-bold">{{ locale.text1 }}</p>
        <ul class="ml-4 mb-4 mt-2">
          <li>
            <strong class="text-red">- {{ locale.list1.item1 }}</strong>
          </li>
          <li>
            <strong class="text-red">- {{ locale.list1.item2 }}</strong>
          </li>
        </ul>
        <p v-html="locale.text2.replace('%d', formatNumber(rate, null, ' '))"></p>
        <ul class="list-disc ml-4 mb-2 mt-2">
          <li v-html="locale.list2.item1"></li>
          <li v-html="locale.list2.item2"></li>
          <template v-if="package == 'G'">
            <li v-html="locale.list2.item3"></li>
            <li v-html="locale.list2.item4"></li>
          </template>
        </ul>
        <p class="my-4" v-html="locale.text3"></p>
        <template #link>
          <div class="text-center text-gray-700 text-sm mt-4 mb-8">
            <a href="#" class="underline cursor-pointer" data-testid="decline" @click.prevent="emit('decline')">
              {{ locale.proceed_without_protection }}
            </a>
          </div>
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
