import { helpers, required, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import { DateTime } from "luxon";
import Validator from "~/utils/validator";
import { userDOB } from "~/utils";

export default {
  address: {
    required: helpers.withMessage(validationJson.required, required),
  },
  addressNumber: {
    required: helpers.withMessage(validationJson.required, required),
  },
  addressCity: {
    required: helpers.withMessage(validationJson.required, required),
  },
  addressCap: {
    required: helpers.withMessage(validationJson.required, required),
  },
  residenceSince: {
    required: helpers.withMessage(validationJson.required, required),
    minValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string) => {
        return DateTime.fromFormat(value, "MM/yyyy") >= userDOB();
      },
    ),
    maxValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string) => {
        return DateTime.fromFormat(value, "MM/yyyy") <= DateTime.now();
      },
    ),
  },
  housingType: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf( function (value: string, { showHousingType }: any) {
        return !!showHousingType;
      }),
    ),
  },
  monthlyRent: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { showMonthlyRent }: any) {
        return !!showMonthlyRent.value
      }),
    ),
    nonZero: helpers.withMessage(
      validationJson.zero_not_allowed,
      Validator.nonZeroIf("showMonthlyRent"),
    ),
  },
  sameAddresses: {
    required: helpers.withMessage(validationJson.required, required),
  },
  address2: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { sameAddresses }: any) {
        return sameAddresses.value !== null && !sameAddresses.value;
      }),
    ),
  },
  addressNumber2: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { sameAddresses }: any) {
        return sameAddresses.value !== null && !sameAddresses.value;
      }),
    ),
  },
  addressCap2: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { sameAddresses }: any) {
        return sameAddresses.value !== null && !sameAddresses.value;
      }),
    ),
  },
  addressCity2: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { sameAddresses }: any) {
        return sameAddresses.value !== null && !sameAddresses.value;
      }),
    ),
  },
};
