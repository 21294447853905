<script setup lang="ts">
import ResultsWithinMinutes from "~/components/static/ResultsWithinMinutes.vue";
const locale = await getLocale("ui", "selected_amount")

defineProps({
  value: { type: Number, required: true },
  customClass: { type: String, required: false }
});
</script>

<template>
  <div
    class="bg-yellow text-center lg:text-left mt-4 flex flex-wrap lg:flex-no-wrap items-center py-2 px-2 md:px-12"
  >
    <h1
      class="text-center lg:text-left text-2xl md:text-3xl font-semibold text-gray-700 leading-tight flex-1"
      :class="customClass"
    >
      <span v-if="$slots.header">
        <slot name="header" />
      </span>
      <span v-else>
        {{locale.title.replace("%d", value?.toLocaleString("it-IT"))}}
      </span>
    </h1>
    <div
      class="hidden sm:flex w-full sm:w-auto justify-center ml-auto mt-2 sm:mt-0"
    >
      <ResultsWithinMinutes />
    </div>
  </div>
</template>

<style scoped></style>
