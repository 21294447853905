import { helpers, required } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";
import { DateTime } from "luxon";
import { AGE_LIMIT } from "~/utils/constants";
import { userDOB } from "~/utils";

export default {
  identityType: {
    required: helpers.withMessage(validationJson.required, required),
  },
  documentNumber: {
    required: helpers.withMessage(validationJson.required, required),
    documentNumberValid: helpers.withMessage(
      validationJson.document_number,
      Validator.documentNumber
    ),
  },
  documentCity: {
    required: helpers.withMessage(validationJson.required, required),
  },
  documentExpire: {
    required: helpers.withMessage(validationJson.required, required),
    minValue: helpers.withMessage(
      validationJson.document_expire,
      (value: string) => {
        return DateTime.fromFormat(value, "dd/MM/yyyy") >= DateTime.now();
      },
    ),
    maxValue: helpers.withMessage(
      validationJson.document_expire,
      (value: string) => {
        return DateTime.fromFormat(value, "dd/MM/yyyy") <= DateTime.now().plus({ years: 11 });
      },
    ),
  },
  documentRelease: {
    required: helpers.withMessage(validationJson.required, required),
    minValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string) => {
        return DateTime.fromFormat(value, "dd/MM/yyyy") >= userDOB();
      },
    ),
    maxValue: helpers.withMessage(
      validationJson.document_invalid,
      (value: string) => {
        return DateTime.fromFormat(value, "dd/MM/yyyy") <= DateTime.now();
      },
    ),
  },
};
