import { helpers, required, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";
import { DateTime } from "luxon";
import { userDOB } from "~/utils";

export default {
  employmentType: {
    required: helpers.withMessage(validationJson.required, required),
  },
  employmentSector: {
    nonZero: helpers.withMessage(
      validationJson.required,
      Validator.nonZeroIf("showEmploymentSector"),
    ),
  },
  monthlyPayment: {
    nonZero: helpers.withMessage(
      validationJson.required,
      Validator.nonZeroIf("showMonthlyPayment"),
    ),
  },
  occupation: {
    nonZero: helpers.withMessage(
      validationJson.required,
      Validator.nonZeroIf("showOccupations"),
    ),
  },
  hiringType: {
    nonZero: helpers.withMessage(
      validationJson.required,
      Validator.nonZeroIf("showHiringType"),
    ),
  },
  monthlyIncome: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: any, { showMonthlyIncome }: any) {
        return !!showMonthlyIncome.value;
      }),
    ),
  },
  employedSince: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: string, { showEmployedSince }: any) {
        return !!showEmployedSince.value;
      }),
    ),
    minValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showEmployedSince }) => {
        if (!showEmployedSince.value) return true;
        return DateTime.fromFormat(value, "MM/yyyy") >= userDOB();
      },
    ),
    maxValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showEmployedSince }) => {
        if (!showEmployedSince.value) return true;
        return DateTime.fromFormat(value, "MM/yyyy") <= DateTime.now();
      },
    ),
  },
  employedTo: {
    required: helpers.withMessage(
      validationJson.required,
      requiredIf(function (value: any, { showEmployedTo }: any) {
        return !!showEmployedTo.value;
      }),
    ),
    minValue: helpers.withMessage(
      validationJson.invalid_date,
      (value: string, { showEmployedTo }) => {
        if (!showEmployedTo.value) return true;
        return DateTime.fromFormat(value, "MM/yyyy") >= DateTime.now().startOf("month");
      },
    ),
  },
  familyMemberCount: {
    nonZero: helpers.withMessage(
      validationJson.zero_not_allowed,
      Validator.nonZeroIf("showFamilyMember"),
    ),
  },
  employedFamilyMemberCount: {
    nonZero: helpers.withMessage(
      validationJson.zero_not_allowed,
      Validator.nonZeroIf("showFamilyMember"),
    ),
  },
};
