<script setup lang="ts">
import AutoComplete from "~/components/inputs/AutoComplete.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import Button from "~/components/inputs/Button.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import SelectedAmount from "~/components/ui/SelectedAmount.vue";
import providers from "~/data/email_providers.json";

import Validator from "~/utils/validator";

const locale = await getLocale("forms", "email_privacy")
import { useVuelidate } from "@vuelidate/core";
import { yesNoOptions } from "~/utils/values";
import emailPrivacyFormRules from "~/rules/emailPrivacy";

defineComponent({
  name: "EmailPrivacy",
});

defineProps({
  progress: { type: Number, required: true },
});

const emit = defineEmits(["init", "submit"]);

const value = useState<number>("credit");

const email = useState<string>("email", () => "");
const privacy1 = useState<boolean | null>("privacy1", () => null);
const privacy2 = useState<boolean | null>("privacy2", () => null);
const privacy3 = useState<boolean | null>("privacy3", () => null);
const privacy4 = useState<boolean | null>("privacy4", () => null);

const v$ = useVuelidate(emailPrivacyFormRules, {
  email,
  privacy1,
  privacy2,
  privacy3,
  privacy4,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};
const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar v-if="progress" :value="progress" />
      <form class="form-container" @submit.prevent="submit">
        <SelectedAmount :value="value" :custom-class="'text-xl px-6'">
          <template #header v-if="$slots.header">
            <slot name="header" />
          </template>
        </SelectedAmount>
        <div
          class="flex flex-wrap text-gray-700 p-4 pb-4 px-4 sm:px-12 rounded-b-lg"
        >
          <p class="text-center text-lg sm:text-left mt-2 mb-4 w-full">
            {{ locale.title }}
          </p>
          <div class="w-full lg:w-1/2 lg:pr-2 mb-6">
            <AutoComplete
              :uppercase="false"
              v-model="email"
              delimiter="@"
              :values="providers.map(domain => ({ value: domain, label: domain }))"
              :label="locale.email"
              :error="v$.email.$error"
              :error-message="Validator.message(v$.email)"
              test-id="email"
              :type="'email'"
            />
          </div>

          <div class="flex flex-wrap sm:flex-nowrap mb-4">
            <RadioButton
              v-model="privacy1"
              :values="yesNoOptions"
              :error="v$.privacy1.$error || (privacy1 !== null && !privacy1)"
              :error-message="
                v$.privacy1.$error
                  ? Validator.message(v$.privacy1)
                  : privacy1 !== null && !privacy1
                    ? locale.privacy1.required
                    : ''
              "
              data-testid="privacy1"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.privacy1.text"
              ></div>
            </RadioButton>
          </div>
          <div class="flex flex-wrap sm:flex-nowrap mb-4">
            <RadioButton
              v-model="privacy2"
              :values="yesNoOptions"
              :error="v$.privacy2.$error || (privacy2 !== null && !privacy2)"
              :error-message="
                v$.privacy2.$error
                  ? Validator.message(v$.privacy2)
                  : privacy2 !== null && !privacy2
                    ? locale.privacy2.required
                    : ''
              "
              data-testid="privacy2"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.privacy2.text"
              ></div>
            </RadioButton>
          </div>
          <div class="flex flex-wrap sm:flex-nowrap mb-4">
            <RadioButton
              v-model="privacy3"
              :values="yesNoOptions"
              :error="v$.privacy3.$error || (privacy3 !== null && !privacy3)"
              :error-message="
                v$.privacy3.$error
                  ? Validator.message(v$.privacy3)
                  : privacy3 !== null && !privacy3
                    ? locale.privacy3.required
                    : ''
              "
              data-testid="privacy3"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.privacy3.text"
              ></div>
            </RadioButton>
          </div>
          <div class="flex flex-wrap sm:flex-nowrap mb-4">
            <RadioButton
              v-model="privacy4"
              :values="yesNoOptions"
              :error="v$.privacy4.$error || (privacy4 !== null && !privacy4)"
              :error-message="
                v$.privacy4.$error
                  ? Validator.message(v$.privacy4)
                  : privacy4 !== null && !privacy4
                    ? locale.privacy4.required
                    : ''
              "
              data-testid="privacy4"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.privacy4.text"
              ></div>
            </RadioButton>
          </div>
        </div>

        <div class="form-footer text-center relative mb-6">
          <Button data-testid="emailPrivacySubmit" class="!px-16" type="submit">
            <span>{{ locale.submit }}</span>
          </Button>
        </div>
      </form>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
