<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
const locale = await getLocale("forms", "club_warning")

defineComponent({
  name: "ClubWarning",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "decline"]);
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="emit('submit')">
        <p class="mb-4" v-html="locale.description"></p>
        <template #link>
          <div class="text-center text-gray-700 mt-4 mb-8">
            <button data-testid="decline" class="underline" @click="emit('decline')">
              {{ locale.proceed_without_club }}
            </button>
          </div>
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped>
@media screen and (max-width: 639px) {
  div :deep(.form-header) {
    font-size: 1rem !important;
  }
}
</style>
