import {
  email as emailValidator,
  helpers,
  required,
  sameAs,
} from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import { email_privacy as email_locale } from "~/static/locale/it/forms.json";

export default {
  email: {
    required: helpers.withMessage(validationJson.required, required),
    email: helpers.withMessage(validationJson.invalid_email, emailValidator)
  },
  privacy1: {
    required: helpers.withMessage(validationJson.required, required),
    sameAsRawValue: helpers.withMessage(
      email_locale.privacy1.required,
      sameAs(true),
    ),
  },
  privacy2: {
    required: helpers.withMessage(validationJson.required, required),
  },
  privacy3: {
    required: helpers.withMessage(validationJson.required, required),
  },
  privacy4: {
    required: helpers.withMessage(validationJson.required, required),
  },
};
