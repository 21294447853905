<script setup lang="ts">
import Select from "~/components/inputs/Select.vue";
import type { IValue } from "~/interfaces";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";
const locale = await getLocale("inputs", "select_date")
import { CURRENT_YEAR } from "~/utils/constants";

const props = defineProps({
  showDay: { default: true, type: Boolean, required: false },
  showMonth: { default: true, type: Boolean, required: false },
  showYear: { default: true, type: Boolean, required: false },
  startYear: { default: 1970, type: Number, required: false },
  endYear: { default: CURRENT_YEAR, type: Number, required: false },
  inputClass: { default: null, type: Object, required: false },
  disabled: { default: false, type: Boolean, required: false },
  error: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
  testId: { type: String, default: "" },
});

const modelValue = defineModel<any>();
const emit = defineEmits(["change"]);

const date = ref({
  day: null,
  month: null,
  year: null,
});

watch(
  modelValue,
  () => {
     date.value = {
       day: null,
       month: null,
       year: null,
     }

    const parts = modelValue.value ? modelValue.value.split("/") : [];
    if (parts.length === 3) {
      date.value.day = parts[0];
      date.value.month = parts[1];
      date.value.year = parts[2];
    } else if (parts.length === 2) {
      date.value.day = null;
      date.value.month = parts[0];
      date.value.year = parts[1];
    }
  },
  { immediate: true },
);

const days = computed(() => {
  const arr: IValue[] = [{ value: null, label: locale.day, disabled: true }];
  for (let i = 1; i <= 31; i++) {
    const day = i < 10 ? "0" + i : i.toString();
    arr.push({ value: day, label: day });
  }
  return arr;
});

const months = computed(() => {
  const arr: IValue[] = [{ value: null, label: locale.month, disabled: true }];
  for (let i = 1; i <= 12; i++) {
    const day = i < 10 ? "0" + i : i.toString();
    arr.push({ value: day, label: day });
  }
  return arr;
});

const years = computed(() => {
  const arr: IValue[] = [{ value: null, label: locale.year, disabled: true }];
  for (let i = props.startYear; i <= props.endYear; i++)
    arr.push({ value: i.toString(), label: i.toString() });
  return arr;
});

const setValue = () => {
  if (props.showDay && props.showMonth && props.showYear) {
    if (date.value.day && date.value.month && date.value.year)
      modelValue.value = [
        date.value.day,
        date.value.month,
        date.value.year,
      ].join("/");
  } else if (props.showMonth && props.showYear) {
    if (date.value.month && date.value.year)
      modelValue.value = [date.value.month, date.value.year].join("/");
  } else if (props.showYear && date.value.year) {
    modelValue.value = date.value.year;
  }
};
</script>

<template>
  <div class="select-date primary">
    <input type="hidden" :value="modelValue" />
    <div class="flex">
      <div v-if="showDay" class="w-full mr-2">
        <Select
          :error="error"
          :error-message="null"
          v-model="date.day"
          :values="days"
          :disabled="disabled"
          :test-id="`${testId}Day`"
          @update:model-value="setValue()"
          @change="emit('change')"
        />
      </div>
      <div v-if="showMonth" class="w-full mr-2">
        <Select
          :error="error"
          :error-message="null"
          v-model="date.month"
          :values="months"
          :disabled="disabled"
          :test-id="`${testId}Month`"
          @update:model-value="setValue()"
          @change="emit('change')"
        />
      </div>
      <div v-if="showYear" class="w-full">
        <Select
          :error="error"
          :error-message="null"
          v-model="date.year"
          :values="years"
          :disabled="disabled"
          :test-id="`${testId}Year`"
          @update:model-value="setValue()"
          @change="emit('change')"
        />
      </div>
    </div>
    <div v-if="error">
      <ErrorMessage :message="errorMessage" />
    </div>
  </div>
</template>

<style scoped></style>
